body {
    font-size: 15px;
    line-height: 1.867;
    font-weight: 300;
    font-family: $body-font;
    color: $text-color;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $title-font; // font-weight: 700;
    color: $title-color;
}

h1 {
    font-size: 48px;
    font-weight: 500;
    line-height: 1.25;
}

h2 {
    font-size: 24px;
    line-height: 1.238;
    font-weight: 400;
}

h3 {
    font-size: 21px;
    font-weight: 400;
}

.mb-60px {
    margin-bottom: 60px;
}

.mt-40px {
    margin-top: 40px;
}

.overflow-hidden {
    overflow: hidden;
}

[class^="flaticon-"]:before,
[class*=" flaticon-"]:before,
[class^="flaticon-"]:after,
[class*=" flaticon-"]:after {
    margin: 0;
}

body {
    &.home3 {
        font-size: 16px;
        line-height: 1.875;
        font-family: $body-font2;
        color: $text-color2;
        font-weight: 300;
    }
}
.home3 {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-family: $title-font2; // font-weight: 700;
		color: $title-color2;
	}
	h2 {
		font-size: 40px;
		font-weight: 700;
		line-height: 1.3;
	}
	h3 {
		font-size: 20px;
		font-weight: 500;
	}
}



@include placeholder {
    text-transform: capitalize;
    font-size: 13px;
    font-weight: 300;
    color: $text-color2;
}