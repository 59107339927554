/*=================== contact banner start ====================*/

.contact-page-area {
    .section-intro {
        h2 {
            font-size: 42px;
            font-weight: 500;
        }
    }
    .contact-btns {
        font-size: 18px;
        font-weight: 600;
        text-align: center;
        padding: 40px;
        color: $white;
        margin-bottom: 30px;
        &:hover {
            color: $white;
        }
    }
    .form-area {
        .form-control {
            margin-bottom: 30px;
            &:focus {
                box-shadow: none !important;
                border-color: #ced4da !important;
            }
        }
        input {
            padding: 15px;
            height: 40px;
            line-height: 40px;
            border: 0px;
            border-bottom: 1px solid $text-color;
        }
        input,
        textarea {
            border-radius: 0;
            font-size: 13px !important;
        }
        textarea {
            height: 160px;
            margin-top: 0px;
            padding: 15px;
        }
        .form-group {
            @media(max-width: 992px) {
                margin-bottom: 0;
            }
        }
    }
    .single-contact-address {
        margin-bottom: 12px;
        h5 {
            font-weight: 400;
            font-size: 16px;
            margin-bottom: 5px;
				}
				p {
					margin-bottom: 5px;
				}
    }
}

/*=================== contact form end ====================*/