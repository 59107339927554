/*font Variables*/
$title-font: 'Rubik', sans-serif;
$body-font: 'Roboto', sans-serif;

$title-font2: 'Poppins', sans-serif;
$body-font2: 'Poppins', sans-serif;

/*Color Variables*/
$primary-color  : #ff512f;
$primary-color2 : #dd2476;
$title-color    : #0f2137;
$text-color     : #465160;
$white          : #fff;

$title-color2: #465064;
$text-color2: #949eb2;
$primary-color3: #ff003c;
$primary-color4: #071590;
$offwhite:      #f8fafd;



/*=================== fonts ====================*/
@import url('https://fonts.googleapis.com/css?family=Rubik:400,500,700');
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400');

@import url('https://fonts.googleapis.com/css?family=Poppins:300, 300i,400,500,700');