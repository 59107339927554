/* Home Banner Area css
============================================================================================ */

.home1 {
    .hero-banner {
        background: url(../img/banner/banner-home1.png) center no-repeat;
        background-size: cover;
        padding-top: 240px;
        padding-bottom: 270px;
        position: relative;
    }
    .banner-card {
        background: rgba(0, 0, 0, .75);
        max-width: 560px;
        padding: 50px;
        @media (max-width: 575px) {
          padding: 30px 20px;
        }
        p {
            color: rgba($white, .60);
            margin-bottom: 0;
        }
        .primary-button {
            margin-top: 45px;
        }
    }
    .banner-title {
        color: $white;
        position: relative;
        margin-bottom: 15px;
        padding-top: 30px;
        @media (max-width: 575px) {
            font-size: 30px;
        }
        &::after {
            content: "";
            display: block;
            width: 50px;
            height: 5px;
            position: absolute;
            top: 0;
            left: 0;
            @extend .gradient-bg;
        }
    }
}

[class*="hero-ani-"] {
    position: absolute;
}

.hero-ani-1 {
    left: 4%;
    bottom: 12%;
}

.hero-ani-2 {
    top: 36%;
    left: 18%;
}

.hero-ani-3 {
    top: 3%;
    left: 2%;
}

.hero-ani-4 {
    right: 13%;
    bottom: 50%;
}

.hero-ani-5 {
    bottom: 19%;
    left: 25%;
}

.hero-ani-6 {
    bottom: 20%;
    left: 47%;
}

.hero-ani-7 {
    top: 13%;
    left: 51%;
}

.hero-ani-8 {
    left: 68%;
    top: 60%;
}

.hero-ani-9 {
    left: 66%;
    top: 26%;
}

.hero-ani-10 {
    top: 10%;
    left: 28%;
}

.hero-ani-11 {
    top: 0;
    left: 0;
}

.hero-ani-12 {
    bottom: 0;
    right: 5%;
}
.hero-ani-13 {
    top: 18%;
    left: 0;
    @media (max-width: 1440px) {
        display: none;
    }
}

.hero-ani-14 {
    bottom: 10%;
    right: 29%;
    @media (max-width: 1440px) {
        display: none;
    }   
}

.home2 {
    .hero-banner {
        @media (min-width: 1921px) {
            margin-top: 150px;
        }
        .container-fluid {
            @media (min-width: 767px) {
                padding-left: 10%;
            }
            @media (min-width: 991px) {
                padding-left: 5%;
            }
            @media (min-width: 1200px) {
                padding-left: 7%;
            }
            @media (min-width: 1665px) {
                padding-left: 21%;
            }
            @media (min-width: 1921px) {
                padding-left: 15px;
                padding-right: 15px;
                max-width: 1140px;
            }
        }
    }
    .banner-img {
        // position: absolute;
        // top: 0;
        // right: 0;
    }
    .banner-card {
        max-width: 460px;
        padding-left: 38px;
        position: relative;
        @media (max-width: 1199px) {
            margin-top: 150px;
        } // @media (max-width: 991px) {
        //   margin-top: 200px;
        // }
        &::after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 3px;
            height: 100%;
            background: rgba(70, 81, 96, .102);
        }
        &::before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 3px;
            height: 50%;
            @extend .gradient-bg;
        }
        .primary-button-home2 {
            background: transparent;
            border: 1px solid #dddddd;
            padding: 9px 30px;
            color: $title-color;
            @media (max-width: 351px) {
                margin-left: 0px !important;
            }
            &:hover {
                @extend .gradient-bg;
                border: 1px solid transparent;
                color: $white;
            }
        }
    }
    .banner-title {
        font-weight: 700;
        margin-bottom: 20px;
        @media (max-width: 767px) {
            font-size: 30px;
        }
    }
}

.home3 {
    .hero-banner {
        background: url(../img/banner/banner-home3.png) left center no-repeat;
        background-size: cover;
        padding-top: 270px;
        padding-bottom: 550px;
        @media (min-width: 1921px) {
            background-position: top;
        }
        @media (max-width: 991px) {
            padding-bottom: 270px;
        }
        [class*="hero-ani-"] {
            @include filter(blur(2px));
        }
        .primary-button-home3 {
            background: rgba($white, .2);
            color: $white;
            &:hover {
                background: $white;
                color: $title-color2;
            }
        }
    }
    .banner-card {
        max-width: 850px;
        margin-right: auto;
        margin-left: auto;
        p {
            color: $white;
            font-size: 18px;
        }
    }
    .banner-title {
        font-size: 60px;
        text-transform: uppercase;
        color: $white;
        font-weight: 700;
        margin-bottom: 15px;
        @media (max-width: 1199px) and (min-width: 992px) {
            font-size: 50px;
        }
        @media (max-width: 991px) {
            font-size: 40px;
        }
        @media (max-width: 575px) {
            font-size: 30px;
        }
    }
}

/* End Home Banner Area css
============================================================================================ */

/* Start Common Banner Area css
============================================================================================ */

.banner_area {
    position: relative;
    min-height: 400px;
    z-index: 1;
    background: $offwhite;
    .banner_inner {
        position: relative;
        overflow: hidden;
        width: 100%;
        min-height: 400px;
        z-index: 1;
        @media (max-width: 991px) {
            margin-top: 70px;
        }
        .banner_content {
            h2 {
                font-size: 45px;
                font-family: $title-font;
                margin-bottom: 0px;
                text-transform: capitalize;
                font-weight: bold;
                color: $title-color;
            }
            .page_link {
                margin-top: 8px;
                a {
                    font-size: 14px;
                    font-family: $title-font;
                    margin-right: 20px;
                    position: relative;
                    color: $text-color;
                    &:before {
                        content: "/";
                        font-size: 14px;
                        position: absolute;
                        right: -15px;
                        top: 54%;
                        transform: translateY(-50%);
                    }
                    &:last-child {
                        margin-right: 0px;
                        &:before {
                            display: none;
                        }
                    }
                    &:hover {
                        color: $primary-color;
                    }
                }
            }
        }
    }
}

/* End Common Banner Area css
============================================================================================ */