/* Start Testimonial Area
=========================================================================================== */

.testimonial-item {
    padding: 45px 40px 40px 50px;
    border: 1px solid #d7dbe3;
    background: $white;
    @include transform(scale(.98));
    @include transition;
    @media (max-width: 575px) {
        padding: 45px 20px;
    }
    &:hover {
        @include transform(scale(1) translateZ(0));
        backface-visibility: hidden;
        -webkit-font-smoothing: subpixel-antialiased;
    }
    >p {
        font-size: 18px;
        font-style: italic;
    }
    .media {
        margin-top: 40px;
        img {
            width: 50px;
            height: 50px;
            margin-right: 20px;
        }
        h4 {
            font-size: 16px;
            margin-bottom: 5px;
        }
        p {
            font-size: 14px;
        }
    }
}

.testimonial-item-wrap {
    padding: 2px;
    @include transition;
    &:hover {
        @extend .gradient-bg;
    }
}

.testimonial-carousel {
    .owl-nav {
        i,
        span {
            font-size: 14px;
            color: #465160;
        }
        .owl-prev,
        .owl-next {
            padding: 0;
            margin: 0;
            width: 45px;
            height: 45px;
            display: block;
            border: 1px solid #d7dbe3;
            border-radius: 50%;
            background: transparent;
            @include transition;
            @media (max-width: 767px) {
                display: none;
            }
            i,
            span {
                color: #465160;
                line-height: 45px;
            }
            &:hover {
                border-color: transparent;
                @extend .gradient-bg;
                box-shadow: 0px 10px 20px 0px rgba(226, 43, 107, 0.4);
                i,
                span {
                    color: $white;
                }
            }
        }
        .owl-prev {
            position: absolute;
            top: -120px;
            right: 55px;
        }
        .owl-next {
            position: absolute;
            top: -120px;
            right: 0;
        }
    }
}

/* End Testimonial Area
=========================================================================================== */

/* Start Testimonial 2 Area
=========================================================================================== */

.testimonial-area {
    padding-bottom: 190px;
    @media(max-width: 1200px) {
        padding-bottom: 150px;
    }
    @media(max-width: 991px) {
        padding-bottom: 50px;
    }
    .owl-carousel .owl-item img {
        width: auto;
    }
    .owl-nav {
        display: -ms-flexbox !important;
        display: flex !important;
        position: absolute;
        bottom: -55px;
        left: 50%;
        @include transform(translateX(-50%));
        @media(max-width: 991px) {
            display: none !important;
        }
        .owl-prev {
            img {
                @include transform-origin(100% 0%);
            }
        }
        .owl-next {
            margin-left: 15px;
            img {
                @include transform-origin(0% 0%);
            }
        }
        img {
            @include transition();
            @include filter(grayscale(110%));
            &:hover {
                @include transform(scalex(1.8));
                @include filter(grayscale(0%));
            }
        }
    }
    .col-lg-4 {
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3) {
            .single-testimonial {
                .testi-bottom {
                    &:before {
                        content: '';
                        position: absolute;
                        top: 0px;
                        left: 40px;
                        height: 3px;
                        width: 90px;
                    }
                }
            }
        }
        &:nth-child(1) {
            .single-testimonial {
                .testi-bottom {
                    &:before {
                        background: #6e35ff;
                    }
                }
            }
        }
        &:nth-child(2) {
            .single-testimonial {
                .testi-bottom {
                    &:before {
                        background: #3bcc66;
                    }
                }
            }
        }
        &:nth-child(3) {
            .single-testimonial {
                .testi-bottom {
                    &:before {
                        background: #f8c72e;
                    }
                }
            }
        }
    }
}

.single-testimonial {
    box-shadow: 0px 10px 30px rgba(111, 54, 255, 0.2);
    margin-bottom: 50px;
    margin-top: 90px;
    @media (min-width: 1200px) {
        margin-top: 50px;
    }
    @media (min-width: 991px) {
        margin-top: 40px;
    }
    @media (min-width: 600px) {
        margin-left: 15px;
        margin-right: 15px;
    }
    @include transition();
    .testi-top {
        padding: 40px;
        background: $white;
        p {
            margin-bottom: 0px;
            margin-top: 25px;
        }
    }
    .testi-bottom {
        background: $offwhite;
        padding: 25px 40px;
        position: relative;
        border-radius: 5px 5px 0px 0px;
        h5 {
            font-size: 16px;
            margin-bottom: 5px;
        }
        p {
            font-size: 14px;
            margin-bottom: 0px;
        }
    }
    &:hover {
        @include transform(translateY(-20px));
    }
}

/* End Testimonial 2 Area
=========================================================================================== */