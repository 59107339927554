// .about{
//   position: relative;
// }
.about-area2 {
    padding-bottom: 82px;
    @media(min-width: 600px) {
        padding-bottom: 102px;
    }
    @media(min-width: 1200px) {
        padding-bottom: 182px;
    }
}
[class*="about-ani-"] {
    position: absolute;
}

.about-ani-1 {
    top: -40%;
    right: 8%;
}

.about-ani-2 {
    top: -14%;
    right: 4%;
}

.about-ani-3 {
    top: -14%;
    right: 29%;
}

.about-ani-4 {
    top: -4%;
    right: 18%;
}

.about-ani-5 {
    top: 23%;
    right: 47%;
}

.about-ani-6 {
    right: 0;
    top: 45%;
}

.about-ani-7 {
    right: 11%;
    top: 47%;
}

.about-ani-8 {
    right: 19.5%;
    top: 64%;
}

.about-ani-9 {
    left: 49%;
    top: 75%;
}

.about-ani-10 {
    left: 59%;
    top: 111%;
}

.about-ani-11 {
    bottom: -15%;
    left: 47%
}

.about-ani-12 {
    bottom: -5%;
    left: 77%;
}

.about-ani-13 {
    right: 7%;
    bottom: -4%;
}

.home3 {
    .about-content {
        h2 {
            font-weight: 700;
        }
    }
}

.about-content {
    max-width: 520px;
    h2 {
        font-size: 42px;
        margin-bottom: 30px;
        font-weight: 500;
        @media (max-width: 767px) {
            font-size: 28px;
        }
    }
    p {
        margin-bottom: 25px;
        max-width: 450px;
    }
}

.about-imgs {
    text-align: right;
    position: relative;
    .about-img2 {
        position: absolute;
        bottom: -95px;
        right: 65px;
        @media (max-width: 1199px) and (min-width: 992px) {
            right: 15px;
        }
    }
}

.about-shape-bg {
    position: relative;
    &::after {
        content: "";
        display: block;
        position: absolute;
        width: 650px;
        height: 850px;
        background: url(../img/home/about-bg-shape.png) left center no-repeat;
        background-size: cover;
        top: -280px;
        right: 0;
        z-index: -1;
    }
}

.team-bg {
    background: url(../img/home/team-bg.png) left center no-repeat;
    background-size: 100% 100%;
}

.card-team {
    border: 1px solid #d7dbe3;
}

.team-footer {
    padding: 30px 30px 30px 40px;
    h3 {
        margin: 0;
    }
    p {
        margin-bottom: 0;
    }
    a {
        display: inline-block;
    }
}

.team-img {
    position: relative;
    overflow: hidden;
    img {
        border-radius: 0 0px 86px 0px;
    }
    &::after {
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(19, 20, 27, 0.6);
        border-radius: 0 0px 86px 0px;
        transform: translateY(-250px) scale(1.05);
        opacity: 0;
        z-index: -1;
        visibility: hidden;
        @include transition;
    }
}

.team-social-wrap {
    position: absolute;
    bottom: 7px;
    right: 40px;
    z-index: 2;
    .team-trigger {
        width: 60px;
        height: 60px;
        display: block;
        border-radius: 50%;
        text-align: center;
        @extend .gradient-bg;
        cursor: pointer;
        position: relative;
        margin-bottom: 17px;
        @include transition();
        &:hover {
            @extend .gradient-bg-reverse;
        }
        i,
        span {
            font-size: 15px;
            color: $white;
            line-height: 60px;
        }
        .team-social {
            position: absolute;
            bottom: 20px;
            left: 50%;
            transform: translateX(-50%) translateY(50px);
            min-height: 200px;
            opacity: 0;
            z-index: -1;
            @include transition;
            visibility: hidden;
            li {
                display: block;
                margin-bottom: 10px;
                &:last-child {
                    margin-bottom: 20px;
                }
                a {
                    display: inline-block;
                    width: 40px;
                    height: 40px;
                    display: block;
                    border-radius: 50%;
                    border: 1px solid rgba(215, 219, 227, 0.35);
                    i,
                    span {
                        line-height: 40px;
                        font-size: 13px;
                        @include transition;
                    }
                    &:hover {
                        color: $title-color;
                        background: $white;
                        border-color: $white;
                        i {
                            color: $title-color;
                        }
                    }
                }
            }
        }
        &:hover {
            .team-social {
                opacity: 1;
                z-index: 1;
                visibility: visible;
                transform: translateX(-50%) translateY(0);
            }
        }
    }
}

.card-team:hover {
    .team-img::after {
        visibility: visible;
        opacity: 1;
        z-index: 1;
        transform: translateY(0) scale(1);
    }
}

.subscribe-bg {
    background: url(../img/home/subscribe-bg.png) left center no-repeat;
    background-size: cover;
    padding: 95px 0;
}

.subscribe-mw {
    max-width: 570px;
    margin-right: auto;
    margin-left: auto;
    p {
        color: $white;
    }
}

.form-subscribe {
    margin: 40px 0 30px 0;
    .form-control {
        border: 1px solid rgba(215, 219, 227, 0.35);
        background: transparent;
        font-size: 13px;
        height: 48px;
        color: $white;
        padding-left: 30px;
        border-radius: 5px !important;
        &::placeholder {
            font-weight: 300;
            color: rgba(255, 255, 255, .5);
        }
        &:focus {
            outline: 0;
            box-shadow: none;
        }
    }
}

.card-blog {
    background: $white;
    overflow: hidden;
}

.blog-footer {
    padding: 35px 30px 30px 40px;
    h3 {
        margin-bottom: 20px;
    }
    p {
        margin-bottom: 0;
    }
    a {
        display: inline-block;
    }
}

.blog-info {
    margin-top: 15px;
    li {
        float: left;
        &::after {
            content: "|";
            padding-right: 8px;
            padding-left: 8px;
        }
        &:last-child::after {
            display: none;
            padding-right: 0;
        }
        a {
            font-size: 13px;
            color: #a7b2c8;
            font-weight: 400;
            &:hover {
                color: #0f2137;
            }
        }
    }
    &::after {
        content: "";
        display: block;
        clear: both;
        display: table;
    }
}

.blog-img {
    position: relative;
    img {
        border-radius: 0 0px 86px 0px;
    }
    .blog-link {
        width: 60px;
        height: 60px;
        display: block;
        border-radius: 50%;
        text-align: center;
        box-shadow: 0px 10px 20px 0px rgba(226, 43, 107, 0.4);
        @extend .gradient-bg;
        position: absolute;
        bottom: 0;
        right: 40px;
        z-index: 5;
        transform: translateX(70px);
        opacity: 0;
        z-index: -1;
        @include transition;
        i,
        span {
            font-size: 15px;
            color: $white;
            line-height: 60px;
        }
        &:hover {
            @extend .gradient-bg-reverse;
        }
    }
    &::after {
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(19, 20, 27, 0.6);
        border-radius: 0 0px 86px 0px;
        transform: translateY(-50px) scale(1.05);
        opacity: 0;
        z-index: -1;
        @include transition;
    }
}

.card-blog:hover {
    .blog-img::after {
        opacity: 1;
        z-index: 1;
        transform: translateY(0) scale(1);
    }
    .blog-link {
        transform: translateX(0);
        opacity: 1;
        z-index: 5;
    }
}

.blog-bg {
    background: url(../img/home/blog-bg.png) left center no-repeat;
    background-size: cover;
}

/* Start Blog Area css
============================================================================================ */

.home-blog-area {
    padding-bottom: 50px;
    @media(min-width: 600px) {
        padding-bottom: 70px;
    }
    @media(min-width: 1200px) {
        padding-bottom: 120px;
    }
}

.single-blog {
    background: $white;
    margin-bottom: 30px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    @include transition();
    .blog-thumb {
        position: relative;
        overflow: hidden;
    }
    .blog-details {
        padding: 40px 35px;
        p {
            margin-bottom: 0;
        }
        .blog-meta {
            margin-top: 40px;
            a {
                font-size: 13px;
                color: $text-color2;
                padding-right: 10px;
                padding-left: 10px;
                border-right: 1px solid $text-color2;
                display: inline-block;
                line-height: 12px;
                @include transition();
                &:first-child {
                    padding-left: 0px;
                }
                &:last-child {
                    padding-right: 0px;
                    border-right: 0px;
                }
                &:hover {
                    color: $text-color;
                }
            }
        }
        h5 {
            margin-bottom: 15px;
            a {
                color: $title-color;
                font-size: 21px;
                @include transition();
                &:hover {
                    color: $primary-color;
                }
            }
        }
    }
    &:hover {}
}

/* End Blog Area css
============================================================================================ */

/* Start Newsletter Area css
============================================================================================ */

.newsletter_area {
    color: #ffffff;
}

.subscription_box {
    background: url(../img/subscribe-bg.jpg) no-repeat center;
    background-size: cover;
    padding: 74px 0px;
    position:relative;
    z-index: 1;
    &:before, {
        content: "";
        position: absolute;
        z-index: -1;
        box-shadow: 0 60px 80px -40px hsla(0, 0%, 0%, 0.5);
        top: 17%;
        bottom: 0px;
        left: 5%;
        right: 5%;
    }
    @media (max-width: 767px) {
        padding: 50px 20px;
    }
    h3 {
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 45px;
    }
    p {
        margin-top: 20px;
        font-size: 14px;
        margin-bottom: 0;
    }
    .subscription {
        input {
            min-width: 74%;
            max-width: 74%;
            line-height: 60px;
            padding: 0 20px;
            border: 0px;
            background: transparent;
            font-size: 14px;
            font-weight: 300;
            border-radius: 5px;
            color: #ffffff;
            &:focus {
                outline: none;
            }
            @media (max-width: 767px) {
                min-width: 56%;
                max-width: 56%;
            }
            @media (max-width: 320px) {
                padding: 0 10px;
            }
            @include placeholder {
                color: rgba($white, .5);
                font-size: 13px;
                font-weight: 300;
            }
        }
    }
    .subcribe-form {
        max-width: 60%;
        margin: 0px auto 0px;
        border: 1px solid rgba($white, .2);
        border-radius: 50px;
        @media (max-width: 1199px) and (min-width: 992px) {
            max-width: 70%;
        }
        @media (max-width: 991px) {
            max-width: 90%;
        }
        @media (max-width: 767px) {
            max-width: 100%;
        }
    }
    .primary-button2  {
        border-radius: 50px;
        min-width: 24%;
        @media (max-width: 767px) {
            padding: 11px 35px;
        }
        @media (max-width: 480px) {
            padding: 11px 20px;
        }
        @media (max-width: 320px) {
            padding: 11px 13px;
        }
        &:hover {
            background: transparent;
        }
    }
}

.subscription .info {
    color: #fff;
    width: 100%;
    font-size: 12px;
    background: transparent;
    position: absolute;
    left: 0;
}

.subscription .info.valid {
    color: green;
}

.subscription .info.error {
    color: red;
}

/* End Newsletter Area css
============================================================================================ */