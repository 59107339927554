/* Start Collaboration Area
=========================================================================================== */

.collaboration-area {
    position: relative;
    @media(min-width: 320px) {
        padding-bottom: 80px;
    }
    @media(min-width: 1200px) {
        padding-bottom: 265px;
    }
    &:after {
        content: '';
        position: absolute;
        top: 0%;
        left: 0;
        width: 100%;
        min-height: 970px;
        background: url(../img/collaboration/collaboration-bg.png) no-repeat center;
        background-position: left top;
        z-index: -1;
    }
    .video-play-box {
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 50%;
        @include transform(translate(-50%, -50%));
        background: rgba($white, 0.9);
        min-width: 80px;
        min-height: 80px;
        line-height: 80px;
        border-radius: 65px;
        text-align: center;
        &:after {
            background: $white;
            z-index: 0;
            @include animation(pulse-border 2000ms ease-out infinite);
            content: "";
            position: absolute;
            left: 50%;
            top: 50%;
            @include transform(translate(-50%, -50%));
            display: block;
            width: 80px;
            height: 80px;
            border-radius: 50%;
            z-index: -1;
        }
        img {
            position: relative;
            top: 8px;
            left: 5px;
        }
    }
    .overlay {
        @include gradient(135deg, $primary-color4 0%, $primary-color3 100%);
        opacity: .85;
    }
    .img-top {
        position: absolute;
        right: 50px;
        top: 50px;
        z-index: 1;
    }
    .text-wrap {
        position: absolute;
        left: 50px;
        bottom: 50px;
        z-index: 1;
        h3 {
            font-size: 20px;
            color: $white;
        }
        p {
            font-size: 14px;
            margin-bottom: 0px;
            color: $text-color2;
        }
    }
    .collaboration-img {
        @media (min-width: 1921px) {
            display: none;
        }
        @media (max-width: 1199px) {
            display: none;
        }
        .img-fluid {
            position: absolute;
        }
        .img-1 {
            right: 29%;
            bottom: 16%;
        }
        .img-2 {
            right: 0%;
            bottom: 15%;
        }
        .img-3 {
            right: 4%;
            bottom: 21%;
            z-index: -1;
        }
    }
}

/* End Collaboration Area
=========================================================================================== */

@include keyframes(pulse-border) {
    0% {
        -webkit-transform: translate(-50%, -50%) translateZ(0) scale(1);
        -moz-transform: translate(-50%, -50%) translateZ(0) scale(1);
        -ms-transform: translate(-50%, -50%) translateZ(0) scale(1);
        -o-transform: translate(-50%, -50%) translateZ(0) scale(1);
        transform: translate(-50%, -50%) translateZ(0) scale(1);
        opacity: 0.7;
    }
    100% {
        -webkit-transform: translate(-50%, -50%) translateZ(0) scale(1.5);
        -moz-transform: translate(-50%, -50%) translateZ(0) scale(1.5);
        -ms-transform: translate(-50%, -50%) translateZ(0) scale(1.5);
        -o-transform: translate(-50%, -50%) translateZ(0) scale(1.5);
        transform: translate(-50%, -50%) translateZ(0) scale(1.5);
        opacity: 0.1;
    }
}