.section-intro-img {
    position: relative;
    display: inline-block;
    margin-bottom: 60px;
    &::after {
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 3px;
        background: #f4f5fa;
        margin-top: 20px;
    }
}

.section-intro-title {
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 500;
    margin-bottom: 5px;
    @extend .gradient-color;
}

.section-intro-white {
    .section-intro-title {
        background: 0;
        background-image: 0;
        color: $white;
        -webkit-text-fill-color: $white;
    }
    h2 {
        color: $white;
    }
}

.home3 {
  .section-intro {
    margin-bottom: 90px;
    @media (max-width: 1200px) {
        margin-bottom: 50px;
    }
    @media (max-width: 991px) {
        margin-bottom: 40px;
    }
    .section-intro-title {
        line-height: 14px;
        margin-bottom: 20px;
        @media (max-width: 1199px) and (min-width: 992px) {
            margin-bottom: 15px;
        }
    }
    h2 {
        @media (max-width: 1199px) and (min-width: 992px) {
            font-size: 35px;
        }
        @media(max-width: 575px) {
            font-size: 30px;
        }
    }
  }
}