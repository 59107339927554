/*----------------------------------------------------
@File: Default Styles
@Author: SPONDON IT

This file contains the styling for the actual theme, this
is the file you need to edit to change the look of the
theme.
---------------------------------------------------- */

/*=====================================================================
@Template Name: Anne
@Developed By: Spondon It

=====================================================================*/ 

/*----------------------------------------------------*/
@import "variables";
/*---------------------------------------------------- */

/*----------------------------------------------------*/
@import "mixins";
/*---------------------------------------------------- */

/*----------------------------------------------------*/
@import "reset";
/*---------------------------------------------------- */

/*----------------------------------------------------*/
@import "predefine";
/*---------------------------------------------------- */

/*----------------------------------------------------*/
@import "header";
/*---------------------------------------------------- */

/*----------------------------------------------------*/
@import "banner";
/*---------------------------------------------------- */

/*----------------------------------------------------*/
@import "section-intro";
/*---------------------------------------------------- */

/*----------------------------------------------------*/
@import "btn";
/*---------------------------------------------------- */

/*----------------------------------------------------*/
@import "home";
/*---------------------------------------------------- */

/*----------------------------------------------------*/
@import "feature";
/*---------------------------------------------------- */

/*----------------------------------------------------*/
@import "service";
/*---------------------------------------------------- */

/*----------------------------------------------------*/
@import "portfolio";
/*---------------------------------------------------- */

/*----------------------------------------------------*/
@import "work";
/*---------------------------------------------------- */

/*----------------------------------------------------*/
@import "testimonial";
/*---------------------------------------------------- */

/*----------------------------------------------------*/
@import "client";
/*---------------------------------------------------- */

/*----------------------------------------------------*/
@import "video";
/*---------------------------------------------------- */

/*----------------------------------------------------*/
@import "contact";
/*---------------------------------------------------- */

/*----------------------------------------------------*/
@import "blog";
/*---------------------------------------------------- */

/*----------------------------------------------------*/
@import "footer";
/*---------------------------------------------------- */

/*----------------------------------------------------*/
@import "footer2";
/*---------------------------------------------------- */






