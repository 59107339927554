/* Footer Area css
============================================================================================ */

.footer_area {
    background: #13141b;
    padding-top: 100px;
    [class*="hero-ani-"] {
        @include filter(blur(3px));
    }
    .single-footer-widget {
        h6 {
            font-size: 16px;
            color: $white;
            margin-bottom: 32px;
        }
        ul li {
            margin-bottom: 8px;
            &:last-child {
                margin-bottom: 0;
            }
            a {
                display: inline-block;
                color: #465160;
                @include transition();
                &:hover {
                    color: $white;
                }
            }
        }
    }
    .footer_bottom {
        margin-top: 100px;
        background: #0e0f18;
        text-align: center;
        padding: 15px 0;
        @media (max-width: 991px) {
            margin-top: 50px;
        }
        p {
            margin-bottom: 0;
        }
        a {
            color: $white;
        }
    }
}

/* End Footer Area css
============================================================================================ */