/* header style start
  ============================================================================================ */

.header_area {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99;
    transition: background 0.4s, all 0.3s linear;
    &.menu-dark {
        .navbar .nav .nav-item .nav-link {
            color: #13141b;
        }
        .primary-button {
            @extend .gradient-bg;
            color: $white !important;
        }
    }
    .navbar-brand {
        margin-right: 30px;
        padding: 0;
    }
    .primary-button {
        background: transparent;
        border: 1px solid #dddddd;
        padding: 9px 30px;
        &:hover {
            @extend .gradient-bg;
            border: 1px solid transparent;
        }
    }
    .menu_nav {
        width: 100%;
        @media (max-width: 991px) {
            margin-top: 30px;
        }
    }
    .navbar {
        background: transparent;
        border: 0px;
        border-radius: 0px;
        width: 100%;
        padding-top: 0;
        padding-bottom: 0;
        @media (max-width: 991px) {
            background: $title-color;
            padding: 16px;
        }
        .navbar-toggler {
            color: $white;
        }
        .nav-right {
            min-width: 140px;
        }
        .nav {
            >.nav-item {
                margin-right: 40px;
                .dropdown-toggle {
                    margin-right: 10px;
                    &:after {
                        content: "\e64b";
                        font-family: 'themify';
                        position: relative;
                        top: 2px;
                        left: 3px;
                        border: 0px;
                        font-size: 9px;
                    }
                }
                >.nav-link {
                    color: $white;
                    text-transform: capitalize;
                    font-weight: 400;
                    padding: 30px 0;
                    display: inline-block;
                    border-top: 4px solid transparent;
                    @media (max-width: 991px) {
                        padding: 10px 0px;
                        border-top: 0px;
                    }
                }
                &.active,
                &:hover {
                    >.nav-link {
                        border-top: 4px solid $white;
                        @media (max-width: 991px) {
                            border-top: 0px;
                        }
                    }
                }
                &.submenu {
                    position: relative;
                    ul {
                        border: none;
                        padding: 0px;
                        border-radius: 0px;
                        box-shadow: none;
                        margin: 0px;
                        background: #fff;
                        @media (max-width: 991px) {
                            background: transparent;
                        }
                        @media (min-width: 992px) {
                            position: absolute;
                            top: 120%;
                            left: 0px;
                            min-width: 210px;
                            text-align: left;
                            opacity: 0;
                            transition: all 300ms ease-in;
                            visibility: hidden;
                            display: block;
                            border: none;
                            padding: 15px 0px;
                            border-radius: 0px;
                            box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.4);
                        }
                        &:before {
                            content: "";
                            width: 0;
                            height: 0;
                            border-style: solid;
                            border-width: 10px 10px 0 10px;
                            border-color: #eeeeee transparent transparent transparent;
                            position: absolute;
                            right: 24px;
                            top: 45px;
                            z-index: 3;
                            opacity: 0;
                            transition: all 400ms linear;
                        }
                        .nav-item {
                            display: block;
                            float: none;
                            margin-right: 0px;
                            margin-left: 0px;
                            transition: all 0.4s linear;
                            @media (max-width: 991px) {
                                border-bottom: 0;
                            }
                            .nav-link {
                                line-height: 35px;
                                font-size: 16px;
                                font-weight: 400;
                                color: $title-color !important;
                                padding: 0px 30px;
                                transition: all 150ms linear;
                                display: block;
                                margin-right: 0px;
                                @include transition();
                                position: relative;
                                &:before {
                                    content: '';
                                    position: absolute;
                                    top: 16px;
                                    left: 10px;
                                    width: 5px;
                                    height: 5px;
                                    background: transparent;
                                    opacity: 0;
                                    visibility: hidden;
                                    @include transition();
                                }
                            }
                            &:last-child {
                                border-bottom: none;
                            }
                            &:hover {
                                .nav-link {
                                    margin-left: 20px;
                                    &:before {
                                        opacity: 1;
                                        visibility: visible;
                                        background: $primary-color;
                                    }
                                }
                            }
                        }
                    }
                    &:hover {
                        ul {
                            @media (min-width: 992px) {
                                visibility: visible;
                                opacity: 1;
                                top: 100%;
                            }
                            .nav-item {
                                margin-top: 0px;
                            }
                        }
                    }
                }
                &:last-child {
                    margin-right: 0px;
                }
            }
        }
    }
    &.navbar_fixed {
        .main_menu {
            position: fixed;
            width: 100%;
            top: -70px;
            left: 0;
            right: 0;
            z-index: 99;
            transform: translateY(70px);
            transition: transform 500ms ease, background 500ms ease;
            -webkit-transition: transform 500ms ease, background 500ms ease;
            box-shadow: 0px 3px 16px 0px rgba(0, 0, 0, 0.1);
            .navbar {
                background: $white;
                .nav {
                    .nav-item {
                        .nav-link {
                            line-height: 20px;
                            color: $title-color;
                        }
                    }
                }
            }
            .primary-button {
                color: $title-color;
                &:hover {
                    color: $white;
                }
            }
            .primary-button2 {
                &:hover {
                    color: $title-color;
                }
            }
        }
    }
}

.header_area {
    .logo_white {
        display: block;
    }
    .logo_black {
        display: none;
    }
    &.navbar_fixed {
        .logo_white {
            display: none;
        }
        .logo_black {
            display: block;
        }
        .navbar .nav>.nav-item {
            &.active,
            &:hover {
                >.nav-link {
                    border-top: 4px solid $title-color !important;
                    @media (max-width: 991px) {
                        border-top: 0px !important;
                    }
                }
            }
        }
        .navbar .navbar-toggler {
            color: $title-color;
        }
    }
}

.home2 {
    .header_area {
        @media (max-width: 1665px) {
            background: $white;
            box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.1);
        }
        .navbar {
            @media (max-width: 1665px) {
                background: $white;
            }
            .navbar-toggler {
                color: $title-color;
            }
        }
        .primary-button {
            @media (max-width: 1665px) {
                color: $title-color;
                &:hover {
                    color: $white;
                }
            }
        }
        .menu_nav {
            -ms-flex-pack: start;
            justify-content: start;
            @media (max-width: 1665px) {
                -ms-flex-pack: center;
                justify-content: center;
            }
        }
        &.navbar_fixed .main_menu {
            .primary-button {
                color: $title-color;
                &:hover {
                    color: $white;
                }
            }
            .navbar {
                background: $white;
            }
        }
        .logo_black {
            display: block;
        }
        .logo_white {
            display: none;
        }
        .navbar-brand {
            @media (min-width: 992px) {
                position: absolute;
                left: 100px;
            }
        }
        .nav-right {
            position: absolute;
            right: 100px;
        }
        .navbar .nav {
            >.nav-item {
                >.nav-link {
                    color: #13141b;
                }
                &.active,
                &:hover {
                    >.nav-link {
                        border-top: 4px solid $title-color;
                        @media (max-width: 991px) {
                            border-top: 0px;
                        }
                    }
                }
            }
        }
    }
}

.home3 {
    .header_area {
        .navbar-brand {
            position: absolute;
            left: 100px;
            @media(max-width: 991px) {
                position: relative;
                left: 30px;
            }
            @media(max-width: 575px) {
                left: 10px;
            }
        }
        .nav-right {
            position: absolute;
            right: 100px;
        }
        .navbar .nav .nav-item {
            @media (max-width: 1199px) and (min-width: 992px) {
                margin-right: 30px;
            }
            .nav-link {
                font-weight: 500;
                @media (max-width: 1199px) and (min-width: 992px) {
                    font-size: 13px;
                }
            }
        }
    }
}

.home2.common-page .header_area .nav-right {
    position: relative;
    right: 0px;
}

.home2.common-page .header_area .navbar-brand {
    position: relative;
    left: 0px;
}

/* header style end
	============================================================================================ */