.primary-button {
    position: relative;
    font-family: $title-font;
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    color: $white;
    border-radius: 5px;
    padding: 10px 30px;
    @extend .gradient-bg2;
    background-size: 200%;
    cursor: pointer;
    @include transition(all 0.2s ease-in-out);
    &:hover {
        opacity: .9;
        color: $white;
        background-position: 100% 0;
        box-shadow: 0px 10px 20px rgba(226, 43, 107, 0.3);
    }
}

.primary-button2 {
    position: relative;
    font-family: $title-font2;
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    color: $title-color2;
    border-radius: 5px;
    padding: 11px 30px;
    background: $white;
    border: 1px solid #d7dbe3;
    cursor: pointer;
    @include transition(all 0.2s ease-in-out);
    &:hover {
        background: rgba($white, .2);
        color: $white;
    }
}

.blog-button {
    position: relative;
    font-family: $title-font;
    display: inline-block;
    font-size: 13px;
    font-weight: 400;
    color: $title-color;
    border-radius: 0px;
    padding: 2px 36px;
    background: $white;
    border: 1px solid #d7dbe3;
    cursor: pointer;
    @include transition(all 0.2s ease-in-out);
}

.button-subscribe {
    font-family: $title-font;
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    color: $primary-color;
    border-radius: 5px;
    padding: 10px 30px;
    background-color: $white;
    border: 1px solid transparent;
    @include transition();
    &:hover {
        background: transparent;
        border: 1px solid $white;
        color: $white;
    }
}

button {
    cursor: pointer;
}