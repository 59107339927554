/* Start Portfolio Area
=========================================================================================== */

.filter-btn {
    li {
        display: inline-block;
        color: #465160;
        font-weight: 400;
        text-transform: capitalize;
        cursor: pointer;
        @include transition();
        &.active,
        &:hover {
            @extend .gradient-color;
        }
        &::after {
            content: "|";
            padding-right: 15px;
            padding-left: 15px;
        }
        &:last-child {
            padding-right: 0;
        }
        &:last-child::after {
            display: none;
        }
    }
    &::after {
        content: "";
        display: block;
        clear: both;
        display: table;
    }
}

.filters-content {
    overflow: hidden;
}

.single_portfolio {
    position: relative;
    overflow: hidden;
    .single_portfolio_overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(19, 20, 27, 0.9);
        transform: translateY(-250px);
        opacity: 0;
        visibility: hidden;
        z-index: -1;
        @include transition;
    }
    .overlay-content {
        position: absolute;
        bottom: 35px;
        left: 40px;
        h3 {
            font-size: 21px;
            color: $white;
            text-transform: capitalize;
            margin-bottom: 5px;
        }
        p {
            font-size: 14px;
            margin-bottom: 0;
            color: rgba(255, 255, 255, .6);
        }
    }
    .overlay-icon {
        width: 45px;
        height: 45px;
        display: block;
        border-radius: 50%;
        @extend .gradient-bg;
        text-align: center;
        position: absolute;
        top: 40px;
        right: 40px;@include transition();
        &:hover {
            @extend .gradient-bg-reverse;
        }
        i,
        span {
            font-size: 15px;
            line-height: 45px;
            color: $white;
        }
    }
}

.single_portfolio:hover {
    .single_portfolio_overlay {
        opacity: 1;
        z-index: 1;
        visibility: visible;
        transform: translateY(0px);
    }
}

/* End Portfolio Area
=========================================================================================== */

/* Start Latest Project Area
=========================================================================================== */

.latest-project-area {
    padding: 0px 0 50px;
    @media(min-width: 600px) {
        padding: 0px 0 70px;
    }
    @media(min-width: 1200px) {
        padding: 0px 0 120px;
    }
    position: relative;
    &:after {
        content: '';
        position: absolute;
        top: -40%;
        right: 0;
        width: 100%;
        min-height: 1030px;
        background: url(../img/portfolio-bg.png) no-repeat center;
        background-position: right top;
        z-index: -1;
    }
    .container-fluid {
        position: relative;
        padding-left: 20%;
        padding-right: 0;
        @media (min-width: 1921px) {
            padding-left: 15px;
            padding-right: 15px;
            max-width: 1140px;
        }
        @media(max-width: 991px) {
            padding-left: 6%;
            padding-right: 6%;
        }
        @media(max-width: 576px) {
            padding-left: 11%;
        }
    }
    .owl-nav {
        display: -ms-flexbox !important;
        display: flex !important;
        position: absolute;
        top: -80px;
        right: 25%;
        @media(max-width: 1199px) {
            display: none !important;
        }
        .owl-prev {
            img {
                @include transform-origin(100% 0%);
            }
        }
        .owl-next {
            margin-left: 15px;
            img {
                @include transform-origin(0% 0%);
            }
        }
        img {
            @include transition();
            @include filter(grayscale(110%));
            &:hover {
                @include transform(scalex(1.8));
                @include filter(grayscale(0%));
            }
        }
    }
}

.single-project {
    margin-top: 90px;
    background: $white;
    margin-bottom: 30px;
    padding: 50px 40px;
    position: relative;
    margin-left: 25px;
    box-shadow: 0px 10px 15px rgba(#6f36ff, 0.2);
    @include transition();
    &:hover {
        @include transform(translateY(-20px));
    }
    @media (min-width: 1200px) {
        margin-top: 50px;
    }
    @media (min-width: 991px) {
        margin-top: 40px;
    }
    @media(max-width: 575px) {
        margin-left: 0px;
    }
    &.mt--60 {
        @media (max-width: 1440px) {
            margin-top: -37px;
        }
        @media (max-width: 1300px) {
            margin-top: -30px;
        }
        @media (max-width: 1024px) {
            margin-top: -20px;
        }
        @media (max-width: 991px) {
            margin-top: 0px;
        }
    }
    h3 {
        @extend .gradient-color2;
        font-size: 20px;
        line-height: 32px;
        margin-bottom: 10px;
        @media (max-width: 1199px) and (min-width: 992px) {
            font-size: 20px;
        }
        @media (max-width: 576px) {
            font-size: 20px;
        }
    }
    p {
        margin-bottom: 30px;
    }
    a {
        display: inline-block;
        font-size: 14px;
        font-weight: 500;
        color: $text-color2;
        cursor: pointer;
        @include transition();
        i {
            position: relative;
            top: 2px;
            @include transition();
        }
    }
    &:hover {
        i,
        a {
            @extend .gradient-color2;
        }
    }
}

/* End Latest Project Area
=========================================================================================== */

/* Start Portfolio Details Area
=========================================================================================== */

.portfolio_details_area {
    .owl-nav {
        display: -ms-flexbox;
        display: flex;
        position: absolute;
        bottom: 100px;
        right: 10%;
        @media(max-width: 1199px) {
            display: none !important;
        }
        .owl-prev {
            img {
                @include transform-origin(100% 0%);
            }
        }
        .owl-next {
            margin-left: 170px;
            img {
                @include transform-origin(0% 0%);
            }
        }
        .owl-prev,
        .owl-next {
            color: $text-color;
            @include transition();
            img {
                @include transition();
                @include filter(grayscale(110%));
            }
            &:hover {
                color: $title-color;
                font-weight: 500;
                img {
                    @include transform(scalex(1.8));
                    @include filter(grayscale(0%));
                }
            }
        }
    }
}

.portfolio_right_text {
    h3 {
        color: $title-color;
        font-size: 42px;
        margin-bottom: 18px;
        font-weight: 500;
        @media(max-width: 991px) {
            font-size: 30px;
        }
    }
    p {
        margin-bottom: 0px;
        font-size: 15px;
    }
    .project-details-box {
        border: 1px solid #eeeeee;
        padding: 50px 40px;
        margin-top: 50px;
        @media(max-width: 1199px) {
            padding: 40px 20px;
        }
        h4 {
            position: relative;
            font-size: 20px;
            margin-bottom: 50px;
            &:after {
                content: '';
                position: absolute;
                top: 38px;
                left: 50%;
                @include transform(translateX(-50%));
                width: 50px;
                height: 2px;
                @extend .gradient-bg;
            }
        }
    }
    .list {
        li {
            margin-bottom: 10px;
            padding-bottom: 10px;
            border-bottom: 1px solid #dddddd;
            &:last-child {
                border-bottom: 0px;
                padding-bottom: 0px;
            }
            .item-name {
                font-size: 16px;
                color: $title-color;
                font-family: $title-font;
                font-weight: 500;
                display: inline-block;
            }
            &:last-child {
                margin-bottom: 0px;
            }
            i {
                color: $text-color;
            }
        }
    }
}

h5 {
    line-height: 28px;
}

.social_details {
    li {
        display: inline-block;
        margin-right: 10px;
        a {
            display: inline-block;
            color: $title-color;
            width: 35px;
            height: 35px;
            line-height: 35px;
            text-align: center;
            font-size: 14px;
            color: $title-color;
            background: transparent;
            border: 1px solid #dddddd;
            border-radius: 30px;
            @include transition();
            &:hover {
                @extend .gradient-bg;
                color: $white;
            }
            @media(max-width: 1199px) {
                margin-bottom: 8px;
            }
        }
        &:last-child {
            margin-right: 0px;
        }
    }
}

/* End Portfolio Details Area
=========================================================================================== */