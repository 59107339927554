/* Blog Area css
============================================================================================ */

.single-post-item {
    position: relative;
    box-shadow: 0px 10px 20px rgba(0, 1, 18, 0.08);
    margin-bottom: 45px;
    figure {
        overflow: hidden;
        margin-bottom: 0px;
    }
    .post-img {
        @include transition();
    }
    .meta-box {
        padding: 35px 40px 40px;
        @media (max-width: 767px) {
            padding: 35px 20px 40px;
        }
    }
    h3 {
        margin-bottom: 15px;
        font-size: 30px;
        font-weight: 500;
        @media (max-width: 767px) {
            font-size: 25px;
        }
        a {
            color: $title-color;
            @include transition();
        }
    }
    p {
        margin-bottom: 0px;
    }
    .post-meta {
        padding-top: 25px;
        margin-top: 20px;
        border-top: 1px solid #e5e5e5;
    }
    .meta-details {
        li {
            a {
                position: relative;
                display: inline-block;
                color: $text-color2;
                padding-right: 20px;
                @include transition();
                &:after {
                    content: '';
                    position: absolute;
                    top: 8px;
                    right: 10px;
                    background: $text-color2;
                    width: 1px;
                    height: 12px;
                    @media (max-width: 767px) {
                        content: none;
                    }
                }
                &:hover {
                    color: $text-color
                }
            }
            &:last-child {
                a {
                    &:after {
                        content: none;
                    }
                }
            }
        }
    }
    &:hover {
        .post-img {
            @include transform(scale(1.3));
        }
        h3 {
            a {
                color: $primary-color;
            }
        }
        .blog-button {
            @extend .gradient-bg;
            color: $white;
            box-shadow: 0px 10px 20px rgba(226, 43, 107, 0.3);
        }
    }
}

.single-post-blog {
    position: relative;
    box-shadow: 0px 10px 20px rgba(0, 1, 18, 0.08);
    margin-bottom: 30px;
    figure {
        overflow: hidden;
        margin-bottom: 0px;
    }
    .post-img {
        @include transition();
    }
    .meta-box {
        padding: 30px;
        @media (max-width: 767px) {
            padding: 30px 20px;
        }
    }
    h3 {
        margin-bottom: 15px;
        font-size: 21px;
        font-weight: 400;
        @media (max-width: 767px) {
            font-size: 25px;
        }
        a {
            color: $title-color;
            @include transition();
        }
    }
    p {
        margin-bottom: 0px;
    }
    .post-meta {
        padding-top: 20px;
        margin-top: 20px;
        border-top: 1px solid #e5e5e5;
    }
    .meta-details {
        li {
            a {
                position: relative;
                display: inline-block;
                color: $text-color2;
                padding-right: 20px;
                @include transition();
                &:after {
                    content: '';
                    position: absolute;
                    top: 8px;
                    right: 10px;
                    background: $text-color2;
                    width: 1px;
                    height: 12px;
                    @media (max-width: 767px) {
                        content: none;
                    }
                }
                &:hover {
                    color: $text-color
                }
            }
            &:last-child {
                a {
                    &:after {
                        content: none;
                    }
                }
            }
        }
    }
    &:hover {
        .post-img {
            @include transform(scale(1.3));
        }
        h3 {
            a {
                @extend .gradient-color;
            }
        }
    }
}

/* End Blog Area css
============================================================================================ */

/* Start Blog Sidebar Area css
============================================================================================ */

.sidebar {
    @media (max-width: 991px) {
        margin-top: 80px;
    }
}

.single-widget {
    border: 1px solid #eee;
    padding: 30px;
    margin-bottom: 30px;
    @media (max-width: 420px) {
        padding: 30px 10px;
    }
    .title {
        font-size: 21px;
        font-weight: 400;
        margin-bottom: 30px;
        line-height: 21px;
    }
}

.search-widget {
    i {
        display: inline-block;
        padding: 18px;
        color: $white;
        font-size: 14px;
        border: 0;
        @extend .gradient-bg;
        box-shadow: 0px 10px 20px rgba(226, 43, 107, 0.3);
    }
    form.example {
        border: 0;
        background: transparent;
        border-radius: 50px;
    }
    form.example input[type="text"] {
        padding: 18px;
        font-size: 14px;
        border: none;
        float: left;
        width: 80%;
        background: transparent;
        color: $text-color;
        &:focus {
            outline: none;
        }
    }
    form.example button {
        float: left;
        width: 20%;
        padding: 6px;
        font-size: 17px;
        border: none;
        cursor: pointer;
        background: transparent;
        &:focus {
            outline: none;
        }
        @media (max-width: 1024px) {
            padding-left: 2px;
        }
    }
    form.example::after {
        content: "";
        clear: both;
        display: table;
    }
}

.author-widget {
    text-align: left;
    h4 {
        font-size: 21px;
        font-weight: 400;
        padding: 25px 0 5px;
    }
    p {
        margin-bottom: 0px;
    }
    ul {
        margin-top: 25px;
        li {
            padding-right: 10px;
            display: inline-block;
            &:last-child {
                margin-right: 0px;
            }
            a {
                display: inline-block;
                width: 30px;
                height: 30px;
                line-height: 30px;
                background: $title-color;
                color: $white;
                border-radius: 20px;
                text-align: center;
                font-size: 12px;
                @include transition();
                &:hover {
                    color: $white;
                    @extend .gradient-bg;
                    box-shadow: 0px 10px 20px rgba(226, 43, 107, 0.3);
                }
            }
        }
    }
}

.category-widget {
    ul {
        li {
            a {
                display: block;
                position: relative;
                color: $text-color;
                margin: 0px;
                border-bottom: 1px solid #eeeeee;
                padding: 10px 0px 10px 20px;
                @include transition();
                &:before {
                    content: "";
                    position: absolute;
                    left: 0px;
                    top: 20px;
                    width: 8px;
                    height: 8px;
                    background: #a6a6a6;
                    @include transition();
                }
            }
            @include transition();
            &:hover {
                a {
                    color: $primary-color;
                    &:before {
                        height: 3px;
                        background: $primary-color;
                        top: 22px;
                    }
                }
            }
            &:last-child {
                a {
                    border-bottom: 0px;
                    padding-bottom: 0px;
                }
            }
        }
    }
}

.popular-posts-widget {
    .single-popular-post {
        padding-bottom: 25px;
        margin-bottom: 25px;
        border-bottom: 1px solid #dddddd;
        &:last-child {
            padding-bottom: 0px;
            margin-bottom: 0px;
            border-bottom: 0px;
        }
        .popular-details {
            h4 {
                line-height: 26px;
                font-size: 16px;
                font-weight: 400;
                margin-top: 10px;
                margin-bottom: 0px;
                @include transition();
                @media (max-width: 1199px) and (min-width: 992px) {
                    font-size: 14px;
                }
            }
            p {
                color: $text-color2;
                margin-bottom: 0px;
                font-size: 13px;
                font-weight: 400;
                line-height: 12px;
            }
        }
        &:hover {
            h4 {
                color: $primary-color;
            }
        }
        @media (max-width: 960px) {
            margin-bottom: 20px;
        }
    }
}

.tag-cloud-widget {
    ul {
        li {
            display: inline-block;
            a {
                display: inline-block;
                border: 1px solid #eee;
                background: $white;
                padding: 5px 22px;
                margin-bottom: 12px;
                margin-right: 5px;
                color: $text-color2;
                font-size: 13px;
                font-weight: 400;
                border-radius: 3px;
                @include transition();
                &:hover {
                    @extend .gradient-bg;
                    box-shadow: 0px 10px 20px rgba(226, 43, 107, 0.3);
                    color: $white;
                }
            }
        }
    }
}

.instagram-widget {
    .instafeed {
        li {
            position: relative;
            overflow: hidden;
            width: 50%;
            padding: 5px;
            &:before {
                content: '';
                position: absolute;
                top: 5px;
                left: 5px;
                bottom: 5px;
                right: 5px;
                background: transparent;
                @include transition();
            }
            .meta {
                width: 100%;
                text-align: center;
                position: absolute;
                top: 50%;
                left: 50%;
                @include transform(translate(-50%, -50%));
                opacity: 0;
                visibility: hidden;
                @include transition();
                a {
                    display: inline-block;
                    font-size: 14px;
                    color: $white;
                }
            }
            &:hover {
                &:before {
                    background: rgba($title-color, .8);
                }
                .meta {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }
}

.newsletter-widget {
    .form-group {
        input {
            width: 100%;
            line-height: 44px;
            padding: 0 15px;
            border-radius: 0;
            border: 1px solid #ddd;
            margin-bottom: 10px;
            display: block;
            &:focus {
                outline: none;
            }
        }
    }
    .primary-btn {
        @extend .gradient-bg;
        color: $white;
        width: 100%;
        line-height: 45px;
        border: 1px solid $primary-color;
        box-shadow: 0px 10px 20px rgba(226, 43, 107, 0.3);
        &:hover {
            background: transparent;
            color: $title-color;
            box-shadow: none;
        }
    }
}

/* End Blog Sidebar Area css
============================================================================================ */

/* Start Blog Details Area css
============================================================================================ */

.blog-details-top {
    margin-bottom: 70px;
    h6 {
        color: $text-color;
    }
    p {
        &:last-of-type {
            margin-bottom: 0px;
        }
    }
    h2 {
        font-size: 42px;
        font-weight: 500;
        margin-bottom: 25px;
        @media (max-width: 991px) {
            font-size: 35px;
        }
        @media (max-width: 767px) {
            font-size: 30px;
        }
    }
}

.blog-details-area {
    h3 {
        font-size: 24px;
        font-weight: 500;
        line-height: 36px;
        margin-bottom: 20px;
    }
    h4 {
        font-size: 21px;
        font-weight: 400;
        margin-bottom: 20px;
    }
    .quote {
        i {
            display: inline-block;
            text-align: center;
            font-weight: 700;
            font-size: 35px;
        }
        .blockquote {
            color: $text-color;
            font-weight: 400;
            font-size: 16px;
        }
    }
    .blog-video {
        position: relative;
        .overlay {
            background: rgba($title-color, .4);
        }
        .video-play-button {
            position: absolute;
            top: 50%;
            left: 50%;
            @include transform(translate(-50%, -50%));
        }
    }
    .blog-tag {
        padding: 29px 0px;
        border-top: 1px solid #dddddd;
        border-bottom: 1px solid #dddddd;
        i {
            font-size: 18px;
        }
        ul {
            margin-left: 10px;
            line-height: 19px;
            li {
                display: inline-block;
                margin-right: 5px;
                a {
                    color: $text-color2;
                    font-size: 14px;
                    font-weight: 400;
                    @include transition();
                    &:hover {
                        color: $primary-color;
                    }
                }
            }
        }
    }
}

/* End Blog Details Area css
============================================================================================ */


/* Start Comment Area css
============================================================================================ */

.comments-area {
    padding-bottom: 50px;
    margin-top: 70px;
    border-bottom: 1px solid #dddddd;
    @media(max-width: 414px) {
        padding:50px 8px;
    }
    h4 {
        font-size: 24px;
        margin-bottom: 50px;
    }
    a {
        color: $title-color;
    }
    .comment-list {
        padding-bottom: 20px;
        &:last-child {
            padding-bottom: 0px;
        }
        &.left-padding {
            padding-left: 25px;
        }
        @media(max-width:413px) {
            .single-comment {
                h5 {
                    font-size: 12px;
                }
                .date {
                    font-size: 11px;
                }
                .comment {
                    font-size: 11px;
                }
            }
        }
    }
    .thumb {
        margin-right: 20px;
    }
    .date {
        font-size: 13px;
        color: #9199a8;
    }
    .comment {
        color: #777777;
    }
    .btn-reply {
        background: transparent;
        padding: 0px;
        color: $text-color;
        display: inline-block;
        font-family: $title-font;
        text-transform: capitalize;
        @include transition();
        &:hover {
            @extend .gradient-color;
        }
    }
}

/* End Comment and Reply Area css
============================================================================================ */

/* Start Comment Form Area css
============================================================================================ */
.comment-form {
    margin-top: 50px;
    padding-bottom: 150px;
    .name {
        padding-left: 0px;
        @media(max-width: 991px) {
            padding-right:0px;
        }
    }
    .email {
        padding-right: 0px;
        @media(max-width: 991px) {
            padding-left:0px;
        }
    }
    .form-control {
        padding: 10px;
        background: #fff;
        border: 1px solid #eeeeee;;
        border-radius: 0px;
        width: 100%;
        font-size: 13px;
        color: #777777;
        &:focus {
            box-shadow: none;
        }
    }
    .primary-button {
        font-size: 12px;
        margin-top: 20px;
    }
}
/* End Comment Form Area css
============================================================================================ */