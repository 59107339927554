/* Start Service Area
=========================================================================================== */
.service-area {
	padding: 80px 0px 40px;
    @media(min-width: 600px) {
        padding: 120px 0px 60px;
    }
    @media(min-width: 1200px) {
        padding: 150px 0px 80px;
    }
}
.single-service {
    margin-bottom: 60px;
    margin-right: 30px;
    @media (max-width: 1480px) {
        margin-right: 0px;
    }
	@include transition();
    img {
		@include filter(grayscale(100%));
        @include transition();
    }
	h4 {
		margin-top: 40px;
		font-size: 20px;
		margin-bottom: 20px;
	}
	p {
		margin-bottom: 0px;
	}
	&:hover {
        img {
			@include filter(grayscale(0%));
        }
	}
}
/* End Service Area
=========================================================================================== */