/* Start Feature Area
=========================================================================================== */

.feature-card-wrapper {
    position: relative;
    padding: 2px;
    transform: scale(1.02) translateZ(0);
    backface-visibility: hidden;
    -webkit-font-smoothing: subpixel-antialiased;
    @include transition;
    &:hover,
    &.active {
        transform: scale(1);
        @extend .gradient-bg;
        .feature-card {
            background: $white;
        }
        .feature-link {
            transform: translateX(0);
            opacity: 1;
            z-index: 1;
        }
    }
}

.feature-card {
    padding: 45px 35px;
    background: transparent;
    z-index: -1;
    @media (max-width: 575px) {
        padding: 45px 20px;
    }
    .feature-icon {
        display: inline-block;
        position: relative;
        margin-bottom: 30px;
        z-index: 1;
        img {
            display: inline-block;
            margin-left: 12px;
            image-rendering: auto;
            image-rendering: crisp-edges;
            image-rendering: pixelated;
        }
        &::after {
            content: "";
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 98px;
            height: 60px;
            background: url(../img/icon-img/feature-icon-bg.png) left center no-repeat;
            background-size: 100% 100%;
            z-index: -1;
            @include animation(spin 4s linear infinite);
        }
    }
    h3 {
        margin-bottom: 20px;
        text-transform: capitalize;
    }
    p {
        margin-bottom: 22px;
    }
    .feature-link {
        display: block;
        width: 45px;
        height: 45px;
        text-align: center;
        border-radius: 50%;
        background-size: 200%;
        @extend .gradient-bg2;
        transform: translateX(-50px);
        opacity: 0;
        z-index: -1;
        @include transition();
        i,
        span {
            color: $white;
            line-height: 45px;
            font-size: 14px;
        }
        &:hover {
            background-position: 100% 0;
        }
    }
}

@keyframes spin {
    100% {
         @include transform(rotate(360deg)); 
    } 
}

.feature-bg {
    background: url(../img/home/feature-bg.png) left center no-repeat;
    background-size: cover;
}

.feature-media {
    padding: 45px 45px 45px 50px;
    background: $white;
    margin-bottom: 10px;
    @include transition;
    @media (max-width: 575px) {
        padding: 45px 15px;
    }
    h3 {
        text-transform: capitalize;
        margin-bottom: 20px;
    }
    p {
        margin-bottom: 0;
    }
    &:hover {
        box-shadow: 0px 20px 50px 0px rgba(153, 153, 153, 0.1);
    }
}

.feature-icon {
    margin-right: 30px;
    font-size: 60px;
    line-height: 1.2;
    [class^="flaticon-"]:before,
    [class*=" flaticon-"]:before,
    [class^="flaticon-"]:after,
    [class*=" flaticon-"]:after {
        font-size: 60px;
        @extend .gradient-color;
        @media (max-width: 575px) {
            font-size: 35px;
        }
    }
}

/* End Feature Area
=========================================================================================== */