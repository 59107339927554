.section-margin {
    margin: 80px 0;
    @media(min-width: 600px) {
        margin: 100px 0;
    }
    @media(min-width: 1200px) {
        margin: 150px 0;
    }
}


.section-padding {
    padding: 80px 0;
    @media(min-width: 320px) {
        padding: 100px 0;
    }
    @media(min-width: 1200px) {
        padding: 120px 0;
    }
}

.section-padding-large {
    @media(min-width: 320px) {
        padding: 80px 0 80px 0;
    }
    @media(min-width: 1200px) {
        padding: 150px 0;
    }
}

ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none;
    transition: all 0.3s ease-in-out;
    &:hover,
    &:focus {
        text-decoration: none;
        outline: none;
    }
}

button:focus {
    outline: none;
    box-shadow: none;
}

.box-shadow-1 {
    box-shadow: 0px 10px 20px 0px rgba(226, 43, 107, 0.4);
}

.bg-gray {
    background: #f8fafd;
}

#back-top {
    position: fixed;
    right: 20px;
    bottom: 30px;
    cursor: pointer;
    z-index: 9999999;
    @media (max-width: 768px) {
        right: 5px;
    }
    a {
        display: inline-block;
        @extend .gradient-bg;
        @extend .box-shadow-1;
        height: 40px;
        width: 40px;
        text-align: center;
        display: block;
        color: $white;
        line-height: 40px;
        font-size: 15px;
        border-radius: 30px;
        @include transition();
        &:hover {
            @extend .gradient-bg-reverse;
        }
    }
}

/* Custom animation start
============================================================================================ */

@media (max-width: 1200px) {
    [class*="hero-ani-"] {
        display: none !important;
    }
}

.custom-animation {
    backface-visibility: hidden;
    animation: jumping 9s ease-in-out 2s infinite alternate;
    animation-delay: 1s;
    transition: all .9s ease 5s;
    user-select: none;
}

.custom-animation2 {
    backface-visibility: hidden;
    animation: jumping2 9s ease-in-out 2s infinite alternate;
    animation-delay: 1s;
    transition: all 1s ease 3s;
    user-select: none;
}

.custom-animation3 {
    backface-visibility: hidden;
    animation: jumping3 9s ease-in-out 2s infinite alternate;
    animation-delay: 1s;
    transition: all 7s ease 2s;
    user-select: none;
}

.custom-animation4 {
    backface-visibility: hidden;
    animation: jumping4 9s ease-in-out 2s infinite alternate;
    animation-delay: 1s;
    transition: all 8s ease 4s;
    user-select: none;
}

.custom-animation10 {
    backface-visibility: hidden;
    animation: jumping10 9s ease-in-out 2s infinite alternate;
    animation-delay: 1s;
    transition: all 8s ease 4s;
    user-select: none;
}

.custom-animation11 {
    backface-visibility: hidden;
    animation: jumping11 9s ease-in-out 2s infinite alternate;
    animation-delay: 1s;
    transition: all 8s ease 4s;
    user-select: none;
}

@keyframes jumping {
    0% {
        transform: translateY(0px) translateX(0) rotate(0) scale(1);
        opacity: .8;
    }
    25% {
        transform: translateY(-10px) translateX(-10px) rotate(20deg) scale(.8);
        opacity: .9;
    }
    50% {
        transform: translateY(-15px) translateX(-15px) rotate(10deg) scale(.9);
        opacity: .8;
    }
    75% {
        transform: translateY(-20px) translateX(-20px) rotate(20deg) scale(.75);
        opacity: .6;
    }
    85% {
        transform: translateY(-25px) translateX(-25px) rotate(20deg) scale(.9);
        opacity: .7;
    }
    100% {
        transform: translateY(-15px) translateX(-15px) rotate(0) scale(.95);
        opacity: .85;
    }
}

@keyframes jumping2 {
    0% {
        transform: translateY(0px) translateX(0) rotate(0) scale(1);
        opacity: .5;
    }
    25% {
        transform: translateY(-30px) translateX(10px) rotate(20deg) scale(.8);
        opacity: .8;
    }
    50% {
        transform: translateY(15px) translateX(-15px) rotate(10deg) scale(.7);
        opacity: .8;
    }
    75% {
        transform: translateY(30px) translateX(20px) rotate(20deg) scale(.75);
        opacity: .7;
    }
    100% {
        transform: translateY(-15px) translateX(15px) rotate(0) scale(.75);
        opacity: .9;
    }
}

@keyframes jumping3 {
    0% {
        transform: translateY(10px) translateX(0) rotate(0) scale(1);
        opacity: .9;
    }
    20% {
        transform: translateY(20px) translateX(10px) rotate(-20deg) scale(.8);
        opacity: .8;
    }
    40% {
        transform: translateY(15px) translateX(-15px) rotate(10deg) scale(.75);
        opacity: .8;
    }
    40% {
        transform: translateY(-15px) translateX(-25px) rotate(10deg) scale(.5);
        opacity: 1;
    }
    80% {
        transform: translateY(-30px) translateX(20px) rotate(-20deg) scale(.75);
        opacity: .6;
    }
    100% {
        transform: translateY(15px) translateX(15px) rotate(0) scale(.95);
        opacity: .7;
    }
}

@keyframes jumping4 {
    0% {
        transform: translateY(-30px) translateX(20px) rotate(0) scale(1.2);
        opacity: .7;
    }
    25% {
        transform: translateY(-20px) translateX(10px) rotate(50deg) scale(.6);
        opacity: .8;
    }
    50% {
        transform: translateY(15px) translateX(-15px) rotate(20deg) scale(.5);
        opacity: .9;
    }
    75% {
        transform: translateY(30px) translateX(20px) rotate(50deg) scale(.75);
        opacity: .7;
    }
    100% {
        transform: translateY(-15px) translateX(15px) rotate(0) scale(.5);
        opacity: .9;
    }
}

@keyframes jumping10 {
    0% {
        transform: rotate(180deg);
        display: block;
    }
    100% {
        transform: rotate(30deg);
        display: block;
    }
}

@keyframes jumping11 {
    0% {
        transform: rotate(180deg) translate(-20px, 20px);
        display: block;
    }
    100% {
        transform: rotate(30deg) translate(0px, 0px);
        display: block;
    }
}

/* Custom animation end
============================================================================================ */

/* Start Gradient Area css
============================================================================================ */

.gradient-bg {
    @include gradient(135deg, $primary-color 32%, $primary-color2 95%);
}
.gradient-bg-reverse {
    @include gradient(135deg, $primary-color2 32%, $primary-color 95%);
}

.border-gradient {
    @include border-gradient(90deg, red 0%, green 100%);
}

.gradient-bg2 {
    @include gradient2(135deg, $primary-color 0%, $primary-color2 49%, $primary-color 100%);
}

.gradient-color {
    @include gradient(135deg, $primary-color 32%, $primary-color2 95%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.gradient-color2 {
    @include gradient(135deg, $primary-color4 0%, $primary-color3 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

/* End Gradient Area css
============================================================================================ */

.overlay {
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
}

// Video Icon
.video-play-button {
	position: relative;
	box-sizing: content-box;
	display: inline-block;
	width: 32px;
	height: 44px;
	border-radius: 50%;
	padding: 46px 8px 18px 28px;
	&:before,
	&:after {
		content: "";
		position: absolute;
		left: 50%;
		top: 50%;
		@include transform(translate(-50%, -50%));
		display: block;
		width: 80px;
		height: 80px;
		background: transparent;
        border-radius: 50%;
        border: 1px solid #dddddd;
	}
	&:before {
		z-index: 0;
		@include animation(pulse-border 2000ms ease-out infinite);
	}
	&:after {
		z-index: 1;
		@include transition(all 200ms ease);
		box-shadow: 0px 10px 30px rgba(39, 0, 110, 0.3);
	}
	span {
		display: block;
		position: relative;
		z-index: 3;
		width: 0;
		height: 0;
		border-left: 16px solid $white;
		border-top: 8px solid transparent;
		border-bottom: 8px solid transparent;
	}
	&:hover {
		&:after {
			// background: $text-color ;
		}
	}
}

@include keyframes(pulse-border) {
	0% {
		@include transform(translate(-50%, -50%) translateZ(0) scale(1));
		opacity: 0.7;
	}
	100% {
		@include transform(translate(-50%, -50%) translateZ(0) scale(1.5));
		opacity: 0.1;
	}
}