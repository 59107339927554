/* Footer2 Area css
============================================================================================ */

.footer-area2 {
    position: relative;
    padding: 130px 0px;
    &.footer-area2-bg {
        &:before {
            content: '';
            position: absolute;
            right: 0px;
            bottom: 0px;
            background: url(../img/footer2-bg.png) no-repeat;
            background-position: right bottom;
            width: 100%;
            height: 1540px;
            z-index: -1;
        }
    }
    .single-footer-widget {
        @media(max-width: 991px) {
            margin-bottom:30px;
        }
        h4 {
            font-size: 20px;
            margin-bottom: 35px;
            @media(max-width: 1024px) {
                font-size: 18px;
            }
            @media(max-width: 991px) {
                margin-bottom: 15px;
            }
        }
        p {
            a {
                color: $primary-color;
                @include transition();
                &:hover {
                    opacity: .7;
                }
            }
        }
        ul {
            li {
                margin-bottom: 8px;
                &:last-child {
                    margin-bottom: 0px;
                }
                a {
                    @include transition();
                    color: $text-color2;
                    &:hover {
                        color: $title-color;
                    }
                }
            }
        }
    }
    .footer-social {
        a {
            display: inline-block;
            width: 40px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            margin-right: 10px;
            background: $white;
            color: $text-color2;
            @include transition();
            &:hover {
                background: $title-color2;
                color: $white;
            }
        }
        i {
            color: #cccccc;
            @include transition();
        }
    }
}

/* End Footer2 Area css
============================================================================================ */