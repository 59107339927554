/* Start Work Area
=========================================================================================== */

.work-area {
	padding-bottom: 50px;
    @media(min-width: 600px) {
        padding-bottom: 70px;
    }
    @media(min-width: 1200px) {
		padding-bottom: 120px;
    }
	position: relative;
    .col-lg-4 {
        &:nth-child(1), &:nth-child(2),&:nth-child(3)  {
            .single-work {
				&:before {
					content: '';
					position: absolute;
					height: 64px;
					z-index: -1;
					@include transition();
				}
            }
        }
		&:nth-child(1), &:nth-child(3) {
            .single-work {
				box-shadow: 0px 10px 30px rgba(111, 54, 255, 0.2);
				&:before {
					bottom: -34px;
					left: 25%;
					width: 0%;
					background: url(../img/dashline1.png) no-repeat;
					z-index: 1;
				}
				&:hover {
					&:before {
						width: 103%;
					}
				}
            }
        }
        &:nth-child(2) {
            .single-work {
				box-shadow: 0px 10px 30px rgba(19, 206, 103, 0.2);
				&:before {
					top: -34px;
					left: 25%;
					width: 0%;
					background: url(../img/dashline2.png) no-repeat;
				}
				&:hover {
					&:before {
						width: 103%;
					}
				}
            }
        }
        &:nth-child(3) {
            .single-work {
				box-shadow: 0px 10px 30px rgba(255, 198, 47, 0.2);
				&:before {
					left: -25%;
					background: url(../img/dashline3.png) no-repeat;
				}
            }
        }
    }
}

.single-work {
    margin-bottom: 30px;
	background: $white;
	padding: 50px 40px;
	@include transition();
    img {
        @include transition();
    }
    h4 {
        margin-top: 30px;
        font-size: 20px;
        margin-bottom: 15px;
    }
    p {
        margin-bottom: 22px;
    }
    a {
		color: $title-color2;
		@include transition();
	}
	&:hover {
		a {
			color: $primary-color4;
		}
	}
}

/* End Work Area
=========================================================================================== */