/* Start Happy Client Area
=========================================================================================== */
.happy-client-area {
	position: relative;
	margin-top: -243px;
	@media (min-width: 1921px) {
		margin-top: 150px;
	}
	@media (max-width: 1199px) and (min-width: 992px) {
        margin-top: -200px;
    }
	@media (max-width: 991px) {
		margin-top:-50px;
	}
	.owl-carousel .owl-item img {
		width: auto!important;
		margin-left: auto;
		@media(max-width: 991px) {
            margin: auto;;
        }
	}
}

/* End Happy Client Area
=========================================================================================== */